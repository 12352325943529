import { FC } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import {
  CreateContactMutation,
  CreateContactMutationVariables,
} from '../../generated/types';
import { QueryCreateContact } from '../../graphql/CreateContactQuery';

interface ContactFormProps {
  onSubmit?: () => void;
}

interface ContactFormData {
  name: string;
  email: string;
  comment: string;
  phone: string;
}

export const ContactForm: FC<ContactFormProps> = ({
  onSubmit: onParentSubmit,
}) => {
  const toast = useToast();
  const [createContact] = useMutation<
    CreateContactMutation,
    CreateContactMutationVariables
  >(QueryCreateContact);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ContactFormData>();

  const onSubmit = async (values: ContactFormData) => {
    const result = await createContact({
      variables: {
        data: values,
      },
    });

    if (result.data?.createContact?.created) {
      toast({
        title: 'Kontaktanfrage geschickt.',
        description:
          'Ihre Kontaktanfrage wurde empfangen. Wir melden uns schnellstmöglich bei Ihnen. Vielen Dank!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      reset();
      onParentSubmit && onParentSubmit();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">Name</FormLabel>
        <Input
          id="name"
          placeholder="Name"
          {...register('name', {
            required: true,
          })}
        />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>

      <FormControl mt={4} isInvalid={!!errors.email}>
        <FormLabel htmlFor="email">E-Mail</FormLabel>
        <Input
          id="email"
          type="email"
          placeholder="E-Mail"
          {...register('email', {
            required: true,
          })}
        />
        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
      </FormControl>

      <FormControl mt={4} isInvalid={!!errors.phone}>
        <FormLabel htmlFor="phone">Telefon (optional)</FormLabel>
        <Input
          id="phone"
          type="tel"
          placeholder="Telefon"
          {...register('phone')}
        />
        <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
      </FormControl>

      <FormControl mt={4} isInvalid={!!errors.comment}>
        <FormLabel htmlFor="comment">Nachricht / Frage</FormLabel>
        <Textarea
          rows={5}
          id="comment"
          {...register('comment', {
            required: true,
          })}
        />
        <FormErrorMessage>{errors.comment?.message}</FormErrorMessage>
      </FormControl>

      <Flex justifyContent="flex-end" mt={6}>
        <Button
          width="100%"
          variant="roundBlue"
          isLoading={isSubmitting}
          type="submit"
        >
          Kontaktanfrage abschicken
        </Button>
      </Flex>
    </form>
  );
};
