import { gql } from '@apollo/client';

export const QueryCreateContact = gql`
  mutation CreateContact($data: CreateContactInput!) {
    createContact(data: $data) {
      errors
      created
    }
  }
`;
